@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
body{
    font-family: 'Nunito', sans-serif;
}
.float{
    position:fixed;
    width:54px;
    height:54px;
    bottom:15px;
    left:15px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    box-shadow: 2px 2px 3px #999;
    z-index:100;
}

.my-float{
    margin-top:13px;
}