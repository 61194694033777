
#navbar{
  top: -100px;
  transition: top 0.5s ease-in-out;
}

#navbar.sticky {
  position: sticky;
  top: 0;
}
#btn{
    transition: all 0.3s;
}
#wp {
    transition: all 0.3s;
}
.gradient-bg-welcome {
    background-color:#0f0e13;
    background-image:
            radial-gradient(at 0% 0%, hsla(253,16%,7%,1) 0, transparent 50%),
            radial-gradient(at 50% 0%, hsl(166deg 39% 30%) 0, transparent 50%),
            radial-gradient(at 100% 0%, hsl(209deg 57% 17% / 98%) 0, transparent 50%);
}

.gradient-bg-services {
    background-color:#0f0e13;
    background-image:
            radial-gradient(at 0% 0%, hsla(253,16%,7%,1) 0, transparent 50%),
            radial-gradient(at 50% 100%, hsla(225,39%,25%,1) 0, transparent 50%);
}

.gradient-bg-transactions {
    background-color: #0f0e13;
    background-image:
            radial-gradient(at 0% 100%, hsla(253,16%,7%,1) 0, transparent 50%),
            radial-gradient(at 50% 0%, hsla(225,39%,25%,1) 0, transparent 50%);
}

.gradient-bg-footer {
    background-color: #0f0e13;
    background-image:
            radial-gradient(at 0% 100%, hsla(253,16%,7%,1) 0, transparent 53%),
            radial-gradient(at 50% 150%, hsla(339,49%,30%,1) 0, transparent 50%);
}

.blue-glassmorphism {
    background: rgb(255, 255, 255, 1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
}

/* white glassmorphism */
.white-glassmorphism {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.eth-card {
    background-color:#ffe699;
    background-image:
            radial-gradient(at 77% 1%, hsla(125,76%,65%,1) 0px, transparent 50%),
            radial-gradient(at 83% 78%, hsla(154,85%,73%,1) 0px, transparent 50%),
            radial-gradient(at 54% 21%, hsla(359,79%,79%,1) 0px, transparent 50%),
            radial-gradient(at 20% 99%, hsla(351,65%,69%,1) 0px, transparent 50%),
            radial-gradient(at 0% 6%, hsla(198,85%,61%,1) 0px, transparent 50%),
            radial-gradient(at 91% 71%, hsla(32,81%,79%,1) 0px, transparent 50%),
            radial-gradient(at 5% 73%, hsla(94,85%,73%,1) 0px, transparent 50%);
}

.text-gradient {
    background-color: #fff;
    background-image: radial-gradient(at 4% 36%, hsla(0,0%,100%,1) 0, transparent 53%), radial-gradient(at 100% 60%, rgb(0, 0, 0) 0, transparent 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
