.results-list {
    width: 60%;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: auto;
}
@media screen and (max-width: 600px) {
    .results-list {
        width: 50%;
    }
}
/*       ScrollBar 2        */

#scrollbar::-webkit-scrollbar {
    width: 12px;
}

#scrollbar::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #F1F3F4;
    border: 1px solid #cacaca;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

#scrollbar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #454545;
}